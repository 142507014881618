const etpLabels = {
    "energyType": [
        {
            "id": 30,
            "mainCategory": "EnergyType",
            "subCategory": null,
            "codeKey": "1",
            "remark": "Thermal",
            "ordinal": 1,
            "hide": 0,
            "codeDesc": "火力"
        },
        {
            "id": 31,
            "mainCategory": "EnergyType",
            "subCategory": null,
            "codeKey": "2",
            "remark": "Pump-Storage",
            "ordinal": 2,
            "hide": 0,
            "codeDesc": "抽蓄水力"
        },
        {
            "id": 32,
            "mainCategory": "EnergyType",
            "subCategory": null,
            "codeKey": "3",
            "remark": "Hydro",
            "ordinal": 3,
            "hide": 0,
            "codeDesc": "慣常水力"
        },
        {
            "id": 33,
            "mainCategory": "EnergyType",
            "subCategory": null,
            "codeKey": "4",
            "remark": "Wind",
            "ordinal": 4,
            "hide": 0,
            "codeDesc": "風力"
        },
        {
            "id": 34,
            "mainCategory": "EnergyType",
            "subCategory": null,
            "codeKey": "5",
            "remark": "Solar",
            "ordinal": 5,
            "hide": 0,
            "codeDesc": "太陽能"
        },
        {
            "id": 120,
            "mainCategory": "EnergyType",
            "subCategory": null,
            "codeKey": "6",
            "remark": "ES",
            "ordinal": 6,
            "hide": 0,
            "codeDesc": "儲能"
        },
        {
            "id": 121,
            "mainCategory": "EnergyType",
            "subCategory": null,
            "codeKey": "7",
            "remark": "DR",
            "ordinal": 7,
            "hide": 0,
            "codeDesc": "需量反應"
        },
        {
            "id": 122,
            "mainCategory": "EnergyType",
            "subCategory": null,
            "codeKey": "8",
            "remark": "OTHER",
            "ordinal": 8,
            "hide": 0,
            "codeDesc": "其他"
        }
    ],
    "customerType": [
        {
            "id": 62,
            "mainCategory": "CustomerType",
            "subCategory": null,
            "codeKey": "1",
            "remark": "TPC",
            "ordinal": 1,
            "hide": 0,
            "codeDesc": "國營發電業"
        },
        {
            "id": 63,
            "mainCategory": "CustomerType",
            "subCategory": null,
            "codeKey": "2",
            "remark": "IPP",
            "ordinal": 2,
            "hide": 0,
            "codeDesc": "民營電廠"
        },
        {
            "id": 64,
            "mainCategory": "CustomerType",
            "subCategory": null,
            "codeKey": "3",
            "remark": "QSE-IPR",
            "ordinal": 3,
            "hide": 0,
            "codeDesc": "民間資源"
        },
        {
            "id": 91,
            "mainCategory": "CustomerType",
            "subCategory": null,
            "codeKey": "4",
            "remark": "OTHER",
            "ordinal": 4,
            "hide": 0,
            "codeDesc": "其他"
        }
    ],
    "fuelType": [
        {
            "id": 35,
            "mainCategory": "FuelType",
            "subCategory": null,
            "codeKey": "1",
            "remark": "Nuclear",
            "ordinal": 1,
            "hide": 0,
            "codeDesc": "核能"
        },
        {
            "id": 36,
            "mainCategory": "FuelType",
            "subCategory": null,
            "codeKey": "2",
            "remark": "Coal",
            "ordinal": 2,
            "hide": 0,
            "codeDesc": "煤"
        },
        {
            "id": 37,
            "mainCategory": "FuelType",
            "subCategory": null,
            "codeKey": "3",
            "remark": "Natural Gas",
            "ordinal": 3,
            "hide": 0,
            "codeDesc": "天然氣"
        },
        {
            "id": 38,
            "mainCategory": "FuelType",
            "subCategory": null,
            "codeKey": "4",
            "remark": "Oil",
            "ordinal": 4,
            "hide": 0,
            "codeDesc": "重油"
        },
        {
            "id": 39,
            "mainCategory": "FuelType",
            "subCategory": null,
            "codeKey": "5",
            "remark": "Diesel",
            "ordinal": 5,
            "hide": 0,
            "codeDesc": "輕油"
        },
        {
            "id": 40,
            "mainCategory": "FuelType",
            "subCategory": null,
            "codeKey": "6",
            "remark": "Geothermal",
            "ordinal": 6,
            "hide": 0,
            "codeDesc": "地熱"
        },
        {
            "id": 41,
            "mainCategory": "FuelType",
            "subCategory": null,
            "codeKey": "7",
            "remark": "Co-gen",
            "ordinal": 7,
            "hide": 0,
            "codeDesc": "汽電共生"
        },
        {
            "id": 42,
            "mainCategory": "FuelType",
            "subCategory": null,
            "codeKey": "8",
            "remark": "Energy Storage",
            "ordinal": 8,
            "hide": 0,
            "codeDesc": "儲能"
        },
        {
            "id": 43,
            "mainCategory": "FuelType",
            "subCategory": null,
            "codeKey": "9",
            "remark": "Demand Response",
            "ordinal": 9,
            "hide": 0,
            "codeDesc": "需量反應"
        },
        {
            "id": 44,
            "mainCategory": "FuelType",
            "subCategory": null,
            "codeKey": "10",
            "remark": "Solar",
            "ordinal": 10,
            "hide": 0,
            "codeDesc": "太陽能"
        },
        {
            "id": 45,
            "mainCategory": "FuelType",
            "subCategory": null,
            "codeKey": "11",
            "remark": "Water",
            "ordinal": 11,
            "hide": 0,
            "codeDesc": "水"
        },
        {
            "id": 46,
            "mainCategory": "FuelType",
            "subCategory": null,
            "codeKey": "12",
            "remark": "Wind",
            "ordinal": 12,
            "hide": 0,
            "codeDesc": "風力"
        },
        {
            "id": 100,
            "mainCategory": "FuelType",
            "subCategory": null,
            "codeKey": "13",
            "remark": "Other",
            "ordinal": 13,
            "hide": 0,
            "codeDesc": "其他"
        }
    ],
    "service": [
        {
            "id": 65,
            "mainCategory": "ProductType",
            "subCategory": null,
            "codeKey": "1",
            "remark": "Energy",
            "ordinal": 1,
            "hide": 0,
            "codeDesc": "電能"
        },
        {
            "id": 66,
            "mainCategory": "ProductType",
            "subCategory": null,
            "codeKey": "2",
            "remark": "dReg",
            "ordinal": 2,
            "hide": 0,
            "codeDesc": "調頻備轉(動態)"
        },
        {
            "id": 67,
            "mainCategory": "ProductType",
            "subCategory": null,
            "codeKey": "3",
            "remark": "sReg",
            "ordinal": 3,
            "hide": 0,
            "codeDesc": "調頻備轉(靜態)"
        },
        {
            "id": 68,
            "mainCategory": "ProductType",
            "subCategory": null,
            "codeKey": "4",
            "remark": "SpinRes",
            "ordinal": 4,
            "hide": 0,
            "codeDesc": "即時備轉"
        },
        {
            "id": 69,
            "mainCategory": "ProductType",
            "subCategory": null,
            "codeKey": "5",
            "remark": "SuppRes",
            "ordinal": 5,
            "hide": 0,
            "codeDesc": "補充備轉"
        },
        {
            "id": 102,
            "mainCategory": "ProductType",
            "subCategory": null,
            "codeKey": "6",
            "remark": "AFC",
            "ordinal": 6,
            "hide": 0,
            "codeDesc": "AFC"
        },
        {
            "id": 103,
            "mainCategory": "ProductType",
            "subCategory": null,
            "codeKey": "7",
            "remark": "FRR",
            "ordinal": 7,
            "hide": 0,
            "codeDesc": "FRR"
        }
    ],
    "srLevel": [],
    "regLevel": [],
    "generatorType": [
        {
            "id": 47,
            "mainCategory": "GeneratorType",
            "subCategory": null,
            "codeKey": "1",
            "remark": "Steam Turbine",
            "ordinal": 1,
            "hide": 0,
            "codeDesc": "汽輪機"
        },
        {
            "id": 48,
            "mainCategory": "GeneratorType",
            "subCategory": null,
            "codeKey": "2",
            "remark": "Combined Cycle",
            "ordinal": 2,
            "hide": 0,
            "codeDesc": "複循環機"
        },
        {
            "id": 49,
            "mainCategory": "GeneratorType",
            "subCategory": null,
            "codeKey": "3",
            "remark": "Gas Turbine",
            "ordinal": 3,
            "hide": 0,
            "codeDesc": "氣渦輪機"
        },
        {
            "id": 50,
            "mainCategory": "GeneratorType",
            "subCategory": null,
            "codeKey": "4",
            "remark": "Diesel Turbine",
            "ordinal": 4,
            "hide": 0,
            "codeDesc": "柴油機"
        },
        {
            "id": 51,
            "mainCategory": "GeneratorType",
            "subCategory": null,
            "codeKey": "5",
            "remark": "Hydro Turbine",
            "ordinal": 5,
            "hide": 0,
            "codeDesc": "水輪機"
        },
        {
            "id": 52,
            "mainCategory": "GeneratorType",
            "subCategory": null,
            "codeKey": "6",
            "remark": "Hydro Pump-Turbine",
            "ordinal": 6,
            "hide": 0,
            "codeDesc": "水輪機(含抽蓄)"
        },
        {
            "id": 53,
            "mainCategory": "GeneratorType",
            "subCategory": null,
            "codeKey": "7",
            "remark": "Wind Turbine",
            "ordinal": 7,
            "hide": 0,
            "codeDesc": "風機"
        },
        {
            "id": 54,
            "mainCategory": "GeneratorType",
            "subCategory": null,
            "codeKey": "8",
            "remark": "Photovoltaic",
            "ordinal": 8,
            "hide": 0,
            "codeDesc": "太陽能"
        },
        {
            "id": 101,
            "mainCategory": "GeneratorType",
            "subCategory": null,
            "codeKey": "9",
            "remark": "Other",
            "ordinal": 9,
            "hide": 0,
            "codeDesc": "其他"
        }
    ],
    "resourceType": [
        {
            "id": 55,
            "mainCategory": "ResourceType",
            "subCategory": null,
            "codeKey": "1",
            "remark": "GEN",
            "ordinal": 1,
            "hide": 0,
            "codeDesc": "發電機組"
        },
        {
            "id": 57,
            "mainCategory": "ResourceType",
            "subCategory": null,
            "codeKey": "2",
            "remark": "DR",
            "ordinal": 2,
            "hide": 0,
            "codeDesc": "需量反應"
        },
        {
            "id": 58,
            "mainCategory": "ResourceType",
            "subCategory": null,
            "codeKey": "3",
            "remark": "ES",
            "ordinal": 3,
            "hide": 0,
            "codeDesc": "併網型儲能設備"
        },
        {
            "id": 59,
            "mainCategory": "ResourceType",
            "subCategory": null,
            "codeKey": "4",
            "remark": "SUPGE",
            "ordinal": 4,
            "hide": 0,
            "codeDesc": "自用發電設備"
        }
    ],
    "unitStatus": [
        {
            "id": 70,
            "mainCategory": "UnitStatus",
            "subCategory": null,
            "codeKey": "1",
            "remark": "available",
            "ordinal": 1,
            "hide": 0,
            "codeDesc": "可調度"
        },
        {
            "id": 71,
            "mainCategory": "UnitStatus",
            "subCategory": null,
            "codeKey": "2",
            "remark": "test",
            "ordinal": 2,
            "hide": 0,
            "codeDesc": "試運轉"
        },
        {
            "id": 72,
            "mainCategory": "UnitStatus",
            "subCategory": null,
            "codeKey": "3",
            "remark": "DSR",
            "ordinal": 3,
            "hide": 0,
            "codeDesc": "動態調度資源"
        },
        {
            "id": 73,
            "mainCategory": "UnitStatus",
            "subCategory": null,
            "codeKey": "4",
            "remark": "fixload",
            "ordinal": 4,
            "hide": 0,
            "codeDesc": "固定負載"
        },
        {
            "id": 74,
            "mainCategory": "UnitStatus",
            "subCategory": null,
            "codeKey": "5",
            "remark": "mustrun",
            "ordinal": 5,
            "hide": 0,
            "codeDesc": "必須運轉"
        },
        {
            "id": 75,
            "mainCategory": "UnitStatus",
            "subCategory": null,
            "codeKey": "6",
            "remark": "annual_repair",
            "ordinal": 6,
            "hide": 0,
            "codeDesc": "定期檢修(大修)"
        },
        {
            "id": 76,
            "mainCategory": "UnitStatus",
            "subCategory": null,
            "codeKey": "7",
            "remark": "repair",
            "ordinal": 7,
            "hide": 0,
            "codeDesc": "檢修"
        },
        {
            "id": 77,
            "mainCategory": "UnitStatus",
            "subCategory": null,
            "codeKey": "8",
            "remark": "temp_repair",
            "ordinal": 8,
            "hide": 0,
            "codeDesc": "臨時檢修(當天)"
        },
        {
            "id": 78,
            "mainCategory": "UnitStatus",
            "subCategory": null,
            "codeKey": "9",
            "remark": "quit",
            "ordinal": 9,
            "hide": 0,
            "codeDesc": "故障"
        }
    ]
}

export default etpLabels