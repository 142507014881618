export {pageData};

let pageData = {
    'login':[
        {
            title: '登入',
            name: 'user',
            items: [
                {dir: 'user', title: '登入頁面', page: 'Login'},
            ]
        }
    ],
    'nav': [
        {
            title: '資源',
            name: 'etp',
            items: [
                {dir: 'etp', title: '儀錶板', page: 'Dashboard'},
                {dir: 'etp', title: '合格交易者資料', page: 'traderInfo'},
                {dir: 'etp', title: '報價代碼', page: 'quoteCode'},
                {dir: 'etp', title: '資源列表', page: 'resourceList'},
                // {dir: 'etp', title: '台灣電力交易平台', page: 'WorkPlace',},
                // {dir: 'etp', title: '登錄者修改', page: 'signerModify'},
                {dir: 'etp', title: '*資源設備檢查', page: 'deviceCheck'},
                {dir: 'etp', title: '*異常故障通報', page: 'faultNotification'},
            ]
        },
        {
            title: '報價',
            name: 'quote',
            items: [
                {dir: 'quote', title: '報價說明', page: 'quoteUploadHelp'},
                {dir: 'quote', title: '未報價資料', page: 'notQuoted'},
                {dir: 'quote', title: '日排程列表', page: 'copList'},
                {dir: 'quote', title: '中止待命', page: 'stopStandby'},
                {dir: 'quote', title: '報價資料檢視', page: 'quoteList'},
                {dir: 'quote', title: '報價資料上傳', page: 'quoteUpload'},
            ]
        },
        {
            title: '結算',
            name: 'settlement',
            items: [
                // {dir: 'settlement', title: '服務完成確認', page: 'serviceConfirm'},
                {dir: 'settlement', title: '通訊缺漏補值', page: 'gapFilling'},
                {dir: 'settlement', title: '通訊補值紀錄', page: 'gapFillingLog'},
                {dir: 'settlement', title: '預結算查詢', page: 'preliminarySettlementQuery'},
                {dir: 'settlement', title: '月結算帳單查詢', page: 'monthlyInvoice'},
                {dir: 'settlement', title: '參與費用作業', page: 'feeManagement'},
                {dir: 'settlement', title: '保證金作業', page: 'depositManagement'},
                {dir: 'settlement', title: '保證金紀錄', page: 'depositHistory'},
            ]
        },
        {
            title: '市場',
            name: 'market',
            items: [
                {dir: 'market', title: '負載預測查詢', page: 'pagePredict'},
                {dir: 'market', title: '結清價格查詢', page: 'pagePrice'},
                {dir: 'market', title: '得標結果查詢', page: 'pageAwarded'},
            ]
        },
    ],
    'drawer': [
        {dir: 'drawer', title: '圖表', page: 'Chart'},
        {dir: 'drawer', title: '派工單詳情', page: 'DispatchDetail'},
        {dir: 'drawer', title: '發電機詳情', page: 'GeneratorDetail'},
    ],
    'page':[
        {dir: 'dispatch', title: '建立派工單', page: 'CreateDispatch'},
        {dir: 'bulletin', title: '建立維修通報', page: 'CreateIssue'},
        {dir: 'completed', title: '處理結果回報',page: 'CreateReport',},
        {dir: 'bulletin', title: '案場維運',page: 'FieldMaintenance',},
        {dir: 'user', title: '通知', page: 'userNotify'},
        // {dir: 'user', title: '設定', page: 'Setting'},
        {dir: 'user', title: '登出', page: 'Logout'},
        // {dir: 'user', title: '測試用', page: 'testPageChange'},
        {dir: 'user', title: '登入頁面', page: 'Login'},
    ],
}
