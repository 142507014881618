<template>
    <div id="layout" :class="{'openSidebar': view.showSubMenu, 'isMobile': view.isMobile, 'loginPage': !view.isLogin}">
        <div id="nav">
            <div id="logo" @click="logoClick"></div>
            <div class="title" v-show="!view.isMobile">電力交易</div>
            <div class="menus">
                <div v-for="k in navData" class="mainMenuItem" @click="mainMenuSelect(k)" :class="{active: k.name === view.mainMenuName}" :key="k.name">
                    {{ k.title }}
                </div>
                <div class="apps" @click.stop.prevent="showApps = true">
                    <Icon type="ios-apps" />
                </div>
            </div>
        </div>
        <Sider id="sidebar" class="subMenu" v-show="view.showSubMenu">
            <div :style="{height: '46px', lineHeight: '44px', textAlign: 'left', borderBottom: 'solid 1px #ccc'}">
                <Button class="fieldSelectorButton" @click="toggleSelector()" v-if="view.isLogin">
                    {{ userTitle }}
                </Button>
            </div>
            <List>
                <router-link class="ivu-list-item" :class="{'active': view.pageAreaName === i.dir + '_' + i.page}" :to="`/${i.dir}/${i.page}`" :key="i.page" v-for="i in menuData">{{ i.title }}</router-link>
            </List>
        </Sider>

        <Layout id="content">
            <div class="subMenuSelectorMask" v-show="view.isMobile && view.showSubMenu" @click="view.showSubMenu = false"></div>
            <div v-show="view.isLogin">
                <router-view v-if="isRouterAlive"></router-view>
            </div>
            <div v-show="!view.isLogin && view.ssoReady">
                <Row>
                    <Col span="24" style="text-align: center">
                        <Card style="max-width: 600px;margin: 0 auto 10px auto;">
                            <img src="https://acad.xlri.ac.in/evening/images/login.svg" style="width: 90%;margin: 25px auto;" />
                            <Button @click.stop.prevent="login" style="width: 300px;">繼續使用 牧陽雲</Button><br />
                            <br />
                            <br />
                            <small style="color: #ddd;">{{ view.ssoUrl }}</small>
                            <br />
                        </Card>
                    </Col>
                </Row>
            </div>
        </Layout>

        <Drawer :title="view.drawerTitle" :width="view.drawerWidth" :closable="false" v-model="view.showDrawer" @on-close="view.drawerName='undefined'">
            <component v-bind:is="view.drawerName"
                       :drawerTitle="view.drawerTitle"
                       :drawerParameter="view.drawerParameter"
                       :drawerName="view.drawerName" :openDrawer="view.openDrawer">
            </component>
        </Drawer>

        <div class="fieldSelectorMask" v-show="showSelector" @click="this.showSelector = false"></div>
        <div class="fieldSelectorBox" v-show="showSelector">
            <vxe-table
                style="width: 400px;"
                empty-text="暫無數據"
                show-header-overflow
                :data="etp.traderList">
                <vxe-column field="plant_id" title="公司統編"></vxe-column>
                <vxe-column field="plant_account" title="交易帳號"></vxe-column>
                <vxe-column field="plant_password" title="交易密碼"></vxe-column>
                <vxe-column field="id" title="操作" align="center" fixed="right">
                    <template v-slot="{ rowIndex }">
                        <i-button size="small" @click.prevent.stop="doSelect(rowIndex)">選擇</i-button>
                    </template>
                </vxe-column>
            </vxe-table>
        </div>
    </div>

    <div class="appsMask" v-show="showApps" @click="showApps = false"></div>
    <div class="appsBox" v-show="showApps">
        <div class="accounts" v-if="view.isLogin">
            <h3 class="userInfo">
                <Avatar :src="view.currentUser.avatar" size="31"/>
                {{ view.currentUser.user_name }}
            </h3>
            <a class="accountBtn" href="#" @click.prevent.stop="accountManager">管理帳號</a>
            <a class="logoutBtn" href="#" @click.prevent.stop="logout">登出</a>
        </div>
        <div v-else><h3>請先登入</h3></div>
        <ul v-for="menu in [mainApps, insideApps, otherApps]" :key="menu">
            <li v-for="app in menu" :key="app.title" @click="location.href = app.url" @mouseover="appsHover = app.title" @mouseleave="appsHover = ''"><a :href="app.url">
                <img :src="appsHover === app.title ? app.svg : app.svg2"/><br/>{{ app.title }}
            </a></li>
        </ul>
    </div>
</template>

<script>
import Swal from "sweetalert2"
import {Avatar} from "view-ui-plus";

export default {
    name: 'App',
    components: {Avatar},
    setup() {
    },
    computed: {
        mainApps(){
            return window.appsLink.filter(x=>x.cat === 'main')
        },
        insideApps(){
            if (this.view.isLogin && this.view.currentUser.employee_id > 0)
                return window.appsLink.filter(x=>x.cat === 'inside')
            return []
        },
        otherApps(){
            if (this.view.isLogin)
                return window.appsLink.filter(x=>x.cat === 'other')
            return []
        },
        navData(){
            if (this.view.isLogin) return this.pageData['nav']
            return []
        },
        menuData(){
            if (this.navData){
                let s = this.navData.filter(x=>x.name === this.view.mainMenuName)
                if (s.length > 0) return s[0]["items"]
            }
            return []
        },
        fieldCount(){
            return this.view.selectFieldList.length
        }
    },
    data() {
        return {
            isRouterAlive: true,
            isCollapsed: false,
            showSelector: false,
            showApps: false,
            tempTargetInfo: "",
            mobileSiderShow:false,
            landingPage: "/etp/Dashboard",
            userTitle: "尚在載入交易者資料…",
            appsHover: null,
        }
    },
    unmounted() {
        window.removeEventListener('resize', this.view.handleWindowResize);
    },
    mounted() {
        this.view.handleWindowResize();
        window.addEventListener('resize', this.view.handleWindowResize);
        this.userInit()
        window.etp = this.etp
        window["alert"] = function(msg, timeout, allowOutsideClick) {
            if (typeof allowOutsideClick === "undefined") allowOutsideClick = true;
            Swal.close()
            setTimeout(function(){
                if (timeout !== undefined) {
                    Swal.fire({
                        title: "",
                        html: msg,
                        timer: timeout,
                        showConfirmButton: false,
                        allowOutsideClick: allowOutsideClick,
                    });
                } else {
                    if (msg){
                        Swal.fire({
                            title: msg,
                        });
                    }
                }
            }, 100)
        }
        console.log(this.etp.traderList)
    },
    methods: {
        toggleSelector(){
            this.showSelector = !this.showSelector
            if (this.showSelector){
                this.tempTargetInfo = this.view.targetInfo
            }
        },
        mainMenuSelect(k){
            if (this.view.isMobile){
                if (this.view.mainMenuName === k.name) {
                    this.view.showSubMenu = !this.view.showSubMenu
                }else{
                    this.view.showSubMenu = true
                }
                this.view.mainMenuName = k.name
            }else{
                this.view.mainMenuName = k.name
                let i = k.items[0]
                this.$router.push(`/${i.dir}/${i.page}`)
            }
        },
        doSelect(index){
            this.etp.selectUser(index).then((user) => {
              this.userTitle = user.title
              window.trader = user
              window.plantData = this.etp.plantData
              this.eventBus.emit('afterSelectUser')
              this.etp.code.commonCode().then(() => {
                this.eventBus.emit('afterGetCommonCode')
              })
              this.$forceUpdate()
              this.etp.plant().then(() => {

              })
            })
            this.showSelector = false
        },
        userInit(){
            this.view.ssoCheck().then(()=>{
                if (this.view.isLogin){
                    if (location.pathname === '' || location.pathname === '/'){
                        this.$router.push(this.landingPage)
                    }else{
                        this.$router.push(location.pathname)
                    }
                    this.etp.init().then(()=>{
                        let index = localStorage.getItem('view_etpUserIndex') || 0
                        this.doSelect(index)
                    })
                }
            })
        },
        login(){
            location.href = this.view.ssoUrl
        },
        logout(){
            this.view.isLogin = false
            this.api.get('/api/sso/logout').then(()=>{
                this.userInit()
            })
        },
        accountManager(){
            location.href = 'https://account.greenshepherd.com.tw/?r=' + Math.random()
        },
        reload() {
            this.isRouterAlive = false;
            this.$nextTick(() => {
                this.isRouterAlive = true
            });
            return false
        },
        getFormData(object) {
            let formData = new FormData();
            Object.keys(object).forEach(key => formData.append(key, object[key]));
            return formData;
        },

    },
}
</script>

<style lang="stylus">
</style>
