import axios from "axios";
import etpLabels from "@/plugins/etpLabels";
axios.defaults.withCredentials = true;

export default {
    install: (app) => {
        app.config.globalProperties.etp = new etp_class(app)
    }
}

// if location is localhost, use baseURL = 'http://
let baseURL = ''
if (location.hostname === 'localhost' || location.hostname === '') {
    baseURL = 'http://127.0.0.1:8000'
}else{
    baseURL = 'https://etp.greenshepherd.com.tw'
}
baseURL = 'https://etp.greenshepherd.com.tw'
let plantId = ''

class etp_class{
    app = null
    currentTrader = null
    // 可以使的交易者列表
    traderList = []
    // 交易者可使用的交易代碼列表
    codeList = []
    // 機組列表
    generatorList=[]
    constructor(app){
        this.app = app
        this.currentTrader = {
            'plantId': '', //54075581
            'userAccount': 'test',
            'userPassword': '123456',
            'unitId': [],
            'unitCname': [],
            'plantName': '',
            'title': ''
        }
        this.notice=this.app.config.globalProperties.$Notice
        this.plantData = {}
        this.labels = etpLabels
        this.selectUser = this.selectUser.bind(this)
    }
    getCategoryFullName = (category) => {
        if (category === 'energy') category = 'energyType'
        if (category === 'customer') category = 'customerType'
        if (category === 'fuel') category = 'fuel'
        if (category === 'product') category = 'service'
        if (category === 'service') category = 'service'
        if (category === 'generator') category = 'generatorType'
        if (category === 'resource') category = 'resourceType'
        if (category === 'status') category = 'unitStatus'
        return category
    }

    getLabelList = (category) => {
        category = this.getCategoryFullName(category)
        return this.labels[category]
    }

    getLabelByCodeKey = (category, key) => {
        if (category === undefined || key === undefined) return ''
        let returnItem = ''
        this.getLabelList(category).forEach(item => {
            if (item.codeKey === key) {
                returnItem = item.codeDesc
            }
        })
        return returnItem
    }

    selectUser = user_index => {
        return new Promise((resolve) => {
            let item = this.traderList[user_index]
            if (item === undefined) return
            localStorage.setItem('view_etpUserIndex', user_index)
            console.log(item)
            this.currentTrader = item
            this.currentTrader.title = '交易者 ' + item.plant_id
            plantId = item.plant_id
            axios.get(`${baseURL}/sign?plantId=${plantId}`, {withCredentials: true}).then(res => {
                console.log(res.data)
                if (!item.data){
                    this.plantData = JSON.parse(item.data).data
                    this.plantData.generators.sort((a, b) => {
                        return a.unitId - b.unitId
                    });
                }
                resolve(this.currentTrader)
            })

        })
    };

    init = function () {
        // 初始化，載入交易者列表
        return new Promise((resolve, reject) => {
            let that = this
            axios.get(`/api/etp_data/get`).then(res => {
                console.log(res.data)
                that.traderList = res.data.data
                that.traderList.forEach(item => {
                    item.plantId = item.plant_id
                })
                console.log('etp_data', that.traderList)
                resolve(res);
            }).catch(err => reject(err));
        });
    }
    loadCodeData = function (where) {
        let that = this
        return new Promise((resolve, reject) => {
            axios.get(`/api/etp_code_data/list?where=${where}`).then(res => {
                that.codeList = res.data
                resolve(res);
            }).catch(err => reject(err));
        });
    }

    downloadFile = function (fileName,data=null) {
        // let target = '/downloadFile'
        let url=`${baseURL}/?target=/downloadFile/${fileName}`
        if (data!=null){
            let encodedData = encodeURIComponent(JSON.stringify(data));
            url+=`&data=${encodedData}`
        }
        return new Promise((resolve, reject) => {
            axios.get(url).then(res => {
                resolve(res);
            }).catch(err => reject(err));
        });
    }

    plant = function(){
        return new Promise((resolve, reject) => {
            axios.get(`${baseURL}/?target=/plant`).then(res => {
                if (res.data.msg === "操作成功") {
                    // console.log("操作成功")
                    let unitList = res.data.data.generators
                    unitList = unitList.sort((a, b) => {
                        return a.unitId < b.unitId ? -1 : 1;
                    })
                    console.log(unitList)
                    this.plantData.generators = unitList
                    this.notice.success({
                        title: '操作成功'
                    })
                } else {
                    this.notice.error({
                        title: res.data.msg
                    })
                }
                resolve(res)
            }).catch((err) => {reject(err)});
        })
    }

    // 排程
    cop = {
        'unitPlan': {
            'get': function (plantId, queryDate, unitIds, timeStep) {
                plantId = plantId || '90000001'
                queryDate = queryDate || new Date().Format('yyyy-MM-dd')
                unitIds = unitIds || ["4"]
                timeStep = timeStep || 'H'
                let data = {"queryDate": queryDate, "unitIds": unitIds, "timeStep": timeStep, "plantId": plantId}
                return axios.post(`${baseURL}/?target=/cop/unitPlan&data=${JSON.stringify(data)}`).then(res => {
                    return res.data
                })
            },
            'upload': function (formData) {
                // let target = '/cop/upload/unitPlan'
                return axios.post(`${baseURL}/?target=/cop/upload/unitPlan&action=uploadCsv`, formData).then(res => {
                    if (res.data.msg === undefined) {
                        alert("台電交易平台拒絕要求")
                    } else {
                        alert(res.data.msg)
                    }
                    return res
                })
            },
            'download': function (data) {
                // let target = '/cop/download/unitPlan'
                let formData = new FormData();
                formData.append('data', JSON.stringify(data))
                return axios.post(`${baseURL}/?target=/cop/download/unitPlan&action=downloadData`, formData).then(res => {
                    if (res.data.msg === undefined) {
                        alert("台電交易平台拒絕要求")
                    } else {
                        alert(res.data.msg)
                    }
                    return res
                })
            },
            'update': function (data) {
                let formData = new FormData();
                formData.append('data', JSON.stringify(data))
                return axios.post(`${baseURL}/?target=/cop/update/unitPlan/H&action=abnormalData`, formData).then(res => {
                    if (res.data.msg === undefined) {
                        alert("台電交易平台拒絕要求")
                    } else {
                        alert(res.data.msg)
                    }
                    return res.data
                })
            },
        },
        'quit': {
            'send': function (unitId, day) {
                // 送出中止待命
                if (typeof unitId === "string") unitId = parseInt(unitId)
                if (typeof day === "string") day = parseInt(day)
                return axios.post(`${baseURL}/?target=/cop/quit/unit?unitId=${unitId}%26nextDay=${day}`, {},{withCredentials: true}).then(res => {
                    return res.data
                })
            },
            'list': function (unitId, startDate, endDate) {
                // 中止待命記錄查詢
                let formData = new FormData();
                formData.append('data', JSON.stringify({
                    'startDate': startDate,
                    'endDate': endDate,
                    'unitId': unitId,
                }))
                return axios.post(`${baseURL}/?target=/cop/query/quit/list`, formData).then(res => {
                    return res.data
                })
            }
        },
        'autoUpdate': function(data){
            let formData = new FormData();
            formData.append('data', JSON.stringify(data))
            return axios.post(`${baseURL}/?target=/cop/autoUpdate&action=abnormalData`, formData).then(res => {
                return res.data
            })
        },
    }

    infoboard = {
        // 資訊看板
        'settle_value': function(startDate){
            // 結算
            let data = {
                'startDate': startDate,
            };
            let encodedData = encodeURIComponent(JSON.stringify(data));
            let host = 'https://etp.taipower.com.tw'
            return axios.get(`${baseURL}/?host=${host}&target=/infoboard/settle_value/query&data=${encodedData}`, {withCredentials: true})
        },
        'price': function(){
            let host = 'https://etp.taipower.com.tw'
            return axios.get(`${baseURL}/?host=${host}&target=/infoboard/price`, {withCredentials: true})
        }
    }

    loadforecast = {
        // 負載預測
        'periodSummary': function(startDate, endDate){
            // 期間總結
            let data = {
                'startDate': startDate,
                'endDate': endDate,
            };
            let encodedData = encodeURIComponent(JSON.stringify(data));
            return axios.get(`${baseURL}/?target=/loadforecast/periodSummary&data=${encodedData}&action=no_params`, {withCredentials: true})
        },
    }

    bid_announce = {
        'summary': function(queryDate){
            let data = {
                'queryDate': queryDate,
            };
            let encodedData = encodeURIComponent(JSON.stringify(data));
            return new Promise((resolve, reject) => {
                axios.get(`${baseURL}/?target=/bid_announce/summary&data=${encodedData}&action=no_params`).then(res => {
                    resolve(res);
                }).catch(err => reject(err));
            });
        },
    }

    code = {
        'resourceType': function(){
            return axios.get(`${baseURL}/?target=/code/resourceType`).then(res => {
                if (res.data.msg === "操作成功") {
                    this.labels = res.data.data
                }
            });
        },
        'generator':function(){
            return new Promise((resolve, reject) => {
                axios.get(`${baseURL}/?target=/generator/`).then(res => {
                    resolve(res);
                }).catch(err => reject(err));
            });
        },
        'commonCode': function(){
            return axios.get(`${baseURL}/?target=/code/common_code`, {withCredentials: true}).then(res => {
                if (res.data.msg === "操作成功") {
                    this.labels = res.data.data
                }
            });
        },
        'loadFromDB': function () {
            let that = this
            return new Promise((resolve, reject) => {
                axios.get(`/api/etp_code_data/list`).then(res => {
                    that.codeList = res.data
                    resolve(res);
                }).catch(err => reject(err));
            });
        }
    }

    // 通訊
    telemetry = {
        'complement': {
            'upload': function(formData){
                return new Promise((resolve, reject) => {
                    axios.post(`${baseURL}/post_upload?target=/telemetry/complement/qse/upload_csv&action=uploadCsv`, formData).then(res => {
                        resolve(res);
                    }).catch(err => reject(err));
                });
            },
            'getLog': function(date){
                let data = {
                    'date': date,
                };
                let encodedData = encodeURIComponent(JSON.stringify(data));
                return new Promise((resolve, reject) => {
                    axios.get(`${baseURL}/?target=/telemetry/complement/qse/log&data=${encodedData}&action=no_params`).then(res => {
                        resolve(res);
                    }).catch(err => reject(err));
                });
                // return axios.get(`${baseURL}/?target=/telemetry/complement/qse/log&data=${encodedData}`, {withCredentials: true})
            }
        }
    }

    // 機組
    generator = {
        'get': function(){
            return new Promise((resolve, reject) => {
                axios.get(`${baseURL}?target=/generator/${plantId}&action=no_params`).then(res => {
                    resolve(res);
                }).catch(err => reject(err));
            });
        },
        'changeLog': function(unitId){
            return new Promise((resolve, reject) => {
                axios.get(`${baseURL}?target=/generator/${plantId}/${unitId}/changeLog&action=no_params`).then(res => {
                    resolve(res);
                }).catch(err => reject(err));
            });
        },
        'list': function(){},
        'query': function(formData){
            let that=this
            return new Promise((resolve, reject) => {
                axios.post(`${baseURL}/?target=/generator/resource/query`, formData).then(res => {
                    that.generatorList=res.data.data
                    resolve(res);
                }).catch(err => reject(err));
            });
        },

    }

    // 報價
    price = {
        'checkPlantQuotation': function(){
            return axios.post(`${baseURL}/?target=/price/checkPlantQuotation`, {}, {withCredentials: true})
        },
        'exportCvsBidSample': function(unitId){
            let data = {
                'unitId': unitId,
                'plantId': plantId
            };
            let encodedData = encodeURIComponent(JSON.stringify(data));
            return new Promise((resolve, reject) => {
                axios.get(`${baseURL}/get_download?target=/price/exportCvsBidSample&data=${encodedData}&action=getDownload`).then(res=>{
                    resolve(res);
                }).catch(err => reject(err));
            });
        },
        'uploadCvsBid': function(formData){
            let encodedData = encodeURI((new Date().Format('yyyy-MM-dd hh:mm:ss')));
            return new Promise((resolve, reject) => {
                axios.post(`${baseURL}/post_upload?target=/price/uploadCvsBid&ts=${encodedData}&action=uploadCsv`,formData).then(res => {
                    resolve(res);
                }).catch(err => reject(err));
            });

        },
        'limitQueryYM': function(year, month){
            return axios.post(`${baseURL}/?target=/price/limitQueryYM&year=${year}%26month=${month}`, {}, {withCredentials: true})
        },
        'generatorUnitPriceData': function(unitId, startDate, endDate) {
            // 報價記錄查詢
            try {
                if (startDate > endDate) {
                    let temp = endDate
                    endDate = startDate
                    startDate = temp
                }
                startDate = startDate.Format("yyyy-MM-dd")
                endDate = endDate.Format("yyyy-MM-dd")
            } catch (e) {
                console.log(e)
            }

            let formData = new FormData();
            formData.append('data', JSON.stringify({
                'startDate': startDate,
                'endDate': endDate,
                'genUnitId': unitId,
            }));
            return axios.post(`${baseURL}/?target=/price/generatorUnitPriceData`, formData, {withCredentials: true}).then(res => {
                return res.data
            })

        },

    }

    // 通知
    notification = {
        'read': function(){

        },
        'loginer': {
            'list': function(){},
        },
    }

    // 結算
    settlement = {
        'accounting': {
            'add_doubt': function(formData){
                console.log(formData)
                return new Promise((resolve, reject) => {
                    axios.post(`${baseURL}/?target=/settlement/accounting/add_doubt`,formData).then(res => {
                        resolve(res);
                    }).catch(err => reject(err));
                });
            },
            'doubt': {
                'list': function(year,month){
                    let data={
                        'year': year,
                        'month':month,
                        'status':-1
                    };
                    let encodedData = encodeURIComponent(JSON.stringify(data));
                    return new Promise((resolve, reject) => {
                        axios.get(`${baseURL}/?target=/settlement/accounting/doubt/list&data=${encodedData}`).then(res => {
                            resolve(res);
                        }).catch(err => reject(err));
                    });
                },
            },
            'settled': {
                'confirm': function(year,month){
                    let data={
                        'year': year,
                        'month':month,
                    };
                    let encodedData = encodeURIComponent(JSON.stringify(data));
                    return new Promise((resolve, reject) => {
                        axios.get(`${baseURL}/?target=/settlement/accounting/settled/confirm&data=${encodedData}&action=put`).then(res => {
                            resolve(res);
                        }).catch(err => reject(err));
                    });
                },
            },
            'overview': {
                'unit': function(year,month,unit){
                    let data={
                        'year': year,
                        'month':month,
                        'unit':unit
                    };
                    let encodedData = encodeURIComponent(JSON.stringify(data));
                    return new Promise((resolve, reject) => {
                        axios.get(`${baseURL}/?target=/settlement/accounting/overview/unit&data=${encodedData}&action=no_params`).then(res => {
                            resolve(res);
                        }).catch(err => reject(err));
                    });
                },
            },
            'join_fee': {
                'list': function(year){
                    let data={
                        'year': year,
                    };
                    let encodedData = encodeURIComponent(JSON.stringify(data));
                    return new Promise((resolve, reject) => {
                        axios.get(`${baseURL}/?target=/settlement/accounting/join_fee&data=${encodedData}`).then(res => {
                            resolve(res);
                        }).catch(err => reject(err));
                    });
                },
                'uploadFile': function(formData){
                    console.log(formData)
                    return new Promise((resolve, reject) => {
                        axios.post(`${baseURL}/post_upload?target=/settlement/accounting/join_fee/uploadFile&action=uploadImage`,formData).then(res => {
                            resolve(res);
                        }).catch(err => reject(err));
                    });
                },
                'export': function(year){
                    let data={
                        'year': year,
                    };
                    let encodedData = encodeURIComponent(JSON.stringify(data));
                    return new Promise((resolve, reject) => {
                        axios.get(`${baseURL}/?target=/settlement/accounting/join_fee/export&data=${encodedData}&action=getDownload`).then(res => {
                            resolve(res);
                        }).catch(err => reject(err));
                    });
                },
            },
            'margin': {
                'list': function(){
                    return new Promise((resolve, reject) => {
                        axios.get(`${baseURL}/?target=/settlement/accounting/margin`).then(res => {
                            resolve(res);
                        }).catch(err => reject(err));
                    });
                },
                'uploadFile': function(formData){
                    console.log(formData)
                    return new Promise((resolve, reject) => {
                        axios.post(`${baseURL}/post_upload?target=/settlement/accounting/margin/uploadFile&action=uploadImage`,formData).then(res => {
                            resolve(res);
                        }).catch(err => reject(err));
                    });
                },
                'record':function(){
                    return new Promise((resolve, reject) => {
                        axios.get(`${baseURL}/?target=/settlement/accounting/margin/record`).then(res => {
                            resolve(res);
                        }).catch(err => reject(err));
                    });
                }
            }
        },
        'export': {
            'settlement': {
                'qse': function(year,month){
                    let data={
                        'year': year,
                        'month': month,
                    };
                    let action= 'getFile'
                    let encodedData = encodeURIComponent(JSON.stringify(data));
                    return new Promise((resolve, reject) => {
                        axios.get(`${baseURL}/?target=/settlement/export/settlement/qse&data=${encodedData}&action=${action}`).then(res => {
                            resolve(res);
                        }).catch(err => reject(err));
                    });
                },
            },
            'preview': {
                'qse': function(year,month){
                    let that = this
                    let data={
                        'year': year,
                        'month': month,
                    };
                    let action= 'getFile'
                    let encodedData = encodeURIComponent(JSON.stringify(data));
                    return new Promise((resolve, reject) => {
                        axios.get(`${baseURL}/?target=/settlement/export/preview/qse&data=${encodedData}&action=${action}`).then(res => {
                            that.codeList = res.data
                            resolve(res);
                        }).catch(err => reject(err));
                    });
                },
            }
        },
        'preview': {
            'summary': {
                'qse': {
                    'list': function(year,month){
                        let data = {
                            'year': year,
                            'month':month
                        };
                        let encodedData = encodeURIComponent(JSON.stringify(data));
                        let action= 'getFile'
                        return new Promise((resolve, reject) => {
                            axios.get(`${baseURL}/?target=/settlement/preview/summary/qse&data=${encodedData}&action=${action}`).then(res => {
                                resolve(res);
                            }).catch(err => reject(err));
                        });
                    },
                    'unit': function(unit,year,month){
                        let data = {
                            'unit': unit,
                            'year': year,
                            'month':month
                        };
                        let encodedData = encodeURIComponent(JSON.stringify(data));
                        return new Promise((resolve, reject) => {
                            axios.get(`${baseURL}/?target=/settlement/preview/summary/qse/unit&data=${encodedData}`).then(res => {
                                resolve(res);
                            }).catch(err => reject(err));
                        });
                    },
                },
            },
            'query': function(year, month){
                let data = {
                    'year': year,
                    'month': month,
                };
                let encodedData = encodeURIComponent(JSON.stringify(data));
                return axios.get(`${baseURL}/?target=/settlement/preview/summary/qse&data=${encodedData}`, {withCredentials: true})
            }
        },
        'monthly': function(year,month){
            let that = this
            let data={
                'year': year,
                'month': month,
            };
            let encodedData = encodeURIComponent(JSON.stringify(data));
            return new Promise((resolve, reject) => {
                axios.get(`${baseURL}/?target=/settlement/accounting/overview&data=${encodedData}`).then(res => {
                    that.codeList = res.data
                    resolve(res);
                }).catch(err => reject(err));
            });
        },
        'price':{
            'query':function(queryDate){
                let data={
                    'queryDate': queryDate,
                };
                let encodedData = encodeURIComponent(JSON.stringify(data));
                return new Promise((resolve, reject) => {
                    axios.get(`${baseURL}/?target=/settlement/price/query&data=${encodedData}`).then(res => {
                        resolve(res);
                    }).catch(err => reject(err));
                });
            },
        }
    }
}