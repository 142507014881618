import {defineStore} from 'pinia';
// import {getPageName} from "@/operating";
// import { viewEtpUserToDo } from "@/main"
import axios from "axios";

let originalSetInterval = window.setInterval;
let intervalTimerList = [];


export const viewData = defineStore('viewData', {
    state: () => {
        return {
            isLogin: false,
            isMobile: true,
            showSubMenu: true,
            deviceType: null,
            etpUserIsSelected: false,
            currentUser: {},
            currentUserId: '',
            etpUserList: [],
            pageAreaName: '',
            pageTitle: '',
            menuTitle: '',
            mainMenuName: '',
            showDrawer: false,
            drawerName: '',
            drawerTitle: '',
            drawerWidth: 600,
            drawerParameter: {},
            currentPage: null,
            currentDrawer: null,
            reOpen: false,

            filterTypeList: [
                '全部類型', 'hr',
                '焦點案場', '問題案場', 'hr',
                '光電案場', '沼氣案場', '熱泵案場', '儲能案場', '其它案場',
            ],
            dataFieldList: [],
            selectFilter: '全部類型',
            selectCity: '全部地區',
            selectTarget: '全部案場',
            selectFieldList: [],
            searchKeyword: "",

            etpUser: {
                'plantId': '', //54075581
                'userAccount': 'test',
                'userPassword': '123456',
                'unitId': [],
                'unitCname': [],
                'plantName': '',
                'title': '尚在載入交易者資料…'
            },

            regLabel: [
                {'key': 0, 'value': '停權中'},
                {'key': 1, 'value': '廢止'},
                {'key': 2, 'value': '營運中'}],
            productLabel: [
                {'key': 1, 'value': '調頻備轉(增強型)'},
                {'key': 2, 'value': '調頻備轉(動態)'},
                {'key': 3, 'value': '調頻備轉(靜態)'},
                {'key': 4, 'value': '即時備轉'},
                {'key': 5, 'value': '補充備轉'},
                {'key': 9, 'value': '電能移轉複合動態調節備轉'},
            ],
            resourceLabel: [
                {'key': 1, 'value': '發電機組'},
                {'key': 2, 'value': '需量反應'},
                {'key': 3, 'value': '併網型儲能設備'},
                {'key': 4, 'value': '自用發電設備'},
            ],
            fuelLabel: [],
            energyLabel: [],
            generatorLabel: [],
            serviceLabel: [],
            unitStatus: [],
            functionCallTimes: 0,

            ssoUrl: '',
            ssoReady: false,
        }
    },
    getters: {
        getFunctionCallTimes() {
            let v=this
            return v.functionCallTimes
        }
    },
    actions: {
        handleWindowResize() {
            this.windowWidth = window.innerWidth;
            try{
                this.drawerWidth = window.innerWidth * 0.8;
                if (window.innerWidth >= 969) this.drawerWidth = window.innerWidth * 0.6;
                if (window.innerWidth >= 1500) this.drawerWidth = window.innerWidth * 0.4;
            }catch(err){
                console.log(err);
            }
            const mobileWidthThreshold = 768;
            if (this.windowWidth <= mobileWidthThreshold) {
                this.showSubMenu = false;
                this.isMobile = true
            }else{
                this.showSubMenu = true;
                this.isMobile = false
            }
        },
        openDrawer(drawerName, drawerTitle, drawerParameter) {
            drawerTitle = drawerTitle || ''
            drawerParameter = drawerParameter || {}
            this.showDrawer = true
            this.drawerTitle = drawerTitle
            this.drawerParameter = drawerParameter
            setTimeout(() => {
                this.drawerName = drawerName
            }, 330)
        },
        setPageInterval(callback, ms, keep = false) {
            keep = keep || false
            let i = originalSetInterval(callback, ms);
            if (!keep) intervalTimerList.push(i);
        },
        clearPageInterval() {
            intervalTimerList.forEach(i => {
                clearInterval(i);
            });
            intervalTimerList = [];
        },
        async canUserAccess() {
            if (this.isLogin) return true;
            const {data} = await axios.get(`/api/user/current`)
            if (data.login) {
                this.currentUser = data.user
                this.currentUserId = data.user.id
                this.isLogin = true
            }
            return data.login;
        },
        async rememberEtpUser() {
            let v=this
            try {
                if (v.etpUser.plantId === '') {
                    v.reOpen = true
                }
                v.etpUser.plantId = JSON.parse(window.localStorage.getItem('plantId'))
                v.etpUser.userAccount = JSON.parse(window.localStorage.getItem('userAccount'))
                v.etpUser.userPassword = JSON.parse(window.localStorage.getItem('userPassword'))
                v.etpUser.plantName = JSON.parse(window.localStorage.getItem('plantName'))
                v.etpUser.unitId = JSON.parse(window.localStorage.getItem('unitId'))
                v.etpUser.unitCname = JSON.parse(window.localStorage.getItem('unitCname'))
                console.log('OK')
                console.log(v.etpUser)
                v.etpUserIsSelected = true
                return true
            } catch (e) {
                console.log('remember Error')
                v.etpUserIsSelected = false
                return false
            }
        },
        async rememberMiscellaneous() {
            let v=this
            let url = `/api/data/d1.json?loginData={"companyId": "${this.etpUser.plantId}","userAccount": "${this.etpUser.userAccount}","userPassword": "${this.etpUser.userPassword}","captchaCode": ""}&type=get&url=${encodeURIComponent("https://etp-practice.taipower.com.tw/api/code/common_code")}&params={}&referer=${encodeURIComponent("https://etp-practice.taipower.com.tw/admin/registration/qse_front")}`
            try {
                await axios.get(url).then(response => {
                    if (response.data.msg === "操作成功") {
                        console.log("操作成功")
                        let data = response.data.data
                        v.resourceLabel = this.genLabelList(data.resourceType)
                        v.energyLabel = this.genLabelList(data.energyType)
                        v.fuelLabel = this.genLabelList(data.fuelType)
                        v.generatorLabel = this.genLabelList(data.generatorType)
                        v.serviceLabel = this.genLabelList(data.service)
                    }
                })
            } catch (e) {
                console.log(e);
            }
            url = `/api/data/d1.json?loginData={"companyId": "${v.etpUser.plantId}","userAccount": "${v.etpUser.userAccount}","userPassword": "${v.etpUser.userPassword}","captchaCode": ""}&type=get&url=${encodeURIComponent("https://etp-practice.taipower.com.tw/api/plant")}&params={}&referer=${encodeURIComponent("https://etp-practice.taipower.com.tw/admin/registration/qse_front")}`
            try {
                await axios.get(url).then(response => {
                    if (response.data.msg === "操作成功") {
                        // console.log("操作成功")
                        v.etpUser.plantName = response.data.data.plantCname
                        v.etpUser.unitId = []
                        v.etpUser.unitCname = []
                        let unitList = response.data.data.generators
                        unitList = unitList.sort((a, b) => {
                            return a.unitId < b.unitId ? -1 : 1;
                        })
                        unitList.forEach(x => {
                            v.etpUser.unitId.push(x.unitId)
                            v.etpUser.unitCname.push(x.unitCname)
                        })
                    }
                })
            } catch (e) {
                console.log(e);
            }
        },
        genLabelList(data) {
            let labelList = []
            data.map(x => {
                let object = {
                    key: x.codeKey,
                    value: x.codeDesc
                }
                labelList.push(object)
            })
            return labelList
        },
        async beforePageChange() {
            this.clearPageInterval()
        },
        afterPageChange() {
            let path = location.pathname
            let pageName = path.split('/').join('_').substring(1)
            if (pageName.length === 1) pageName = 'components_' + pageName
            this.pageAreaName = pageName
            setTimeout(() => {
                this.mainMenuName = pageName.split("_")[0]
            }, 30)
        },
        async todo_beforePageChange(to, from) {
            // TODO 俢復此功能?
            this.clearPageInterval()
            if (this.currentUserId === '') {
                this.currentUserId = JSON.parse(window.localStorage.getItem('currentUserId'))
            }
            const canAccess = await this.canUserAccess()
            this.isLogin = canAccess
            if (to.path === '/user/Login' || to.path === '/') {
                if (!canAccess) {
                    return true
                } else {
                    if (location.pathname === '' || location.pathname === '/') {
                        console.log('關閉後再開')
                        // return '/user/etpUserChange'
                    } else {
                        return location.pathname
                    }
                }
            }
            if (!canAccess) {
                return '/user/Login'
            }
            const etpUserAccess = await this.rememberEtpUser()
            console.log(this.etpUser)
            if (this.etpUser.plantId === undefined || this.etpUser.plantId === '' || this.etpUser.plantId === null) {
                if (!etpUserAccess && to.path.indexOf('/user/') === -1) {
                    console.log('1check')
                    return '/user/etpUserChange'
                }
                if (!etpUserAccess && to.path.indexOf('/user/userNotify') !== -1) {
                    console.log('2check')
                    return '/user/etpUserChange'
                }
                if (this.etpUser.plantId === null && to.path.indexOf('/user/etpUserChange') === -1) {
                    return '/user/etpUserChange'
                }
            }
            console.log(this.etpUser.plantId)
            console.log(from.path)
            console.log(to.path)
            if (this.etpUser.plantId !== '') {
                // await this.rememberMiscellaneous()
            }
            if (etpUserAccess && canAccess && this.reOpen && this.etpUser.plantId !== '' && this.etpUserIsSelected && to.path === '/') {
                this.reOpen = false
                return '/etp/pageQSEFront'
            }

            // console.log(this.etpUser)
            // if(!this.etpUserIsSelected && canAccess && (to.path.indexOf('/user/')==-1)){
            //     return '/user/etpUserChange'
            // }

        },
        ssoCheck(){
            let v = this
            return new Promise((resolve, reject) => {
                let p = location.hostname
                if (p === 'localhost' || p === '127.0.0.1'){
                    p = `${location.protocol}//${location.hostname}:${location.port}`
                    if (p.endsWith(':')) p = p.substring(0, p.length - 1)
                }else{
                    p = p.replace('.greenshepherd.com.tw', '')
                }

                axios.get(`/api/sso/check?service=${encodeURI(p)}`).then(res => {
                    v.isLogin = res.data.isLogin
                    v.currentUser = res.data.data
                    v.ssoUrl = res.data.url
                    if (v.ssoUrl !== ''){
                        v.ssoReady = true
                    }
                    resolve(res);
                }).catch(err => reject(err));
            });
        },
        getFormData(object) {
            let formData = new FormData();
            Object.keys(object).forEach(key => formData.append(key, object[key]));
            return formData;
        },
        getLabel(labelListName, labelKey) {
            let value = ''
            labelKey = parseInt(labelKey)
            const labelListMap = {
                'reg': this.regLabel,
                'resource': this.resourceLabel,
                'product': this.productLabel,
                'fuel': this.fuelLabel,
                'energy': this.energyLabel,
                'generator': this.generatorLabel,
                'service': this.serviceLabel,
                'unitStatus': this.unitStatus
            };
            if (labelListName in labelListMap) {
                labelListMap[labelListName].map(x => {
                    if (parseInt(x['key']) === labelKey) {
                        value = x['value']
                    }
                })
            }
            return value
        },
    },
})

export default viewData